<script setup lang="ts">
import { computed } from '#imports'
import TransitionExpand from '../transition/Expand.vue'

const open = defineModel<boolean>('open')
const props = defineProps<{
  isThin?: boolean
  itemsAlign?: 'start' | 'center' | 'end'
  expand?: boolean
}>()

const isOpen = computed(() => props.expand || open.value)
const buttonClasses = computed(() => {
  const classes: Record<string, boolean> = {
    'bg-surface': !isOpen.value,
    'bg-primary-50': Boolean(isOpen.value && !props.isThin && !props.expand),
    'border-b': props.expand,
    'p-5': !props.isThin,
    'space-x-2': !props.isThin,
  }

  const alignKey = props.itemsAlign
    ? `items-${props.itemsAlign}`
    : 'items-center'

  classes[alignKey] = true

  return classes
})
</script>

<template>
  <div class="relative flex flex-col items-start justify-start">
    <button
      class="z-1 flex w-full justify-between"
      :class="buttonClasses"
      :aria-expanded="isOpen"
      type="button"
      @click="open = !open"
    >
      <div
        class="flex flex-1 flex-col items-start justify-center"
        :class="{ 'order-2': isThin }"
      >
        <slot v-bind="{ isOpen }" />
      </div>
      <div class="rounded-full p-1">
        <div
          class="i-ph-caret-down-bold h-4 w-4 transform-gpu rounded-full transition-transform"
          :class="{
            'rotate-180': open,
            'rotate-0': !open,
            'opacity-0': expand,
          }"
        />
      </div>
    </button>

    <TransitionExpand>
      <div v-if="isOpen" class="bg-surface z-0 w-full transition-all">
        <slot v-bind="{ isOpen }" name="content" />
      </div>
    </TransitionExpand>
  </div>
</template>
